<template>
  <div class="crm-today">
    <div>
      <ve-table
        v-if="!loading"
        :columns="columns" 
        :table-data="tableData"
        style="width:100%" 
        :scroll-width="400"
      />
      <div v-show="tableData.length == 0 && !loading" class="v-table-empty-data">暂无数据</div>
    </div>
    <loadings :loading='loading' />
  </div>
</template>

<script>
  import Loadings from '@/components/loading'
  import { Cell, CellGroup } from 'vant'
  export default {
    name:'daily',
    data() {
      return {
        today:{},
        week:{},
        high_intention:[],
        loading:false,
        tableData: [],
        list: [],
        columns: [
            {field: 'employee_name', key:'employee_name', title: '校区名称', width: 150,renderBodyCell: ({ row, column, rowIndex }, h) => {return this.userInfo(row)},fixed: "left"},
            {field: 'total_follow', key:'total_follow', title: 'TOTAL', width: 80,},
            {field: 'contract_money_count', key:'contract_money_count', title: '签约金额', width: 90},
            {field: 'leads_follow_count', key:'leads_follow_count', title: '线索跟进', width: 80,},
            {field: 'customer_follow_count', key:'customer_follow_count',  title: '客户跟进', width: 80,},
            {field: 'promise_count', key:'promise_count',  title: '周产诺访', width: 80},
            {field: 'promise_this_week_count',  key:'promise_this_week_count',  title: '本周诺访', width: 80,},
            {field: 'promise_next_week_count', key:'promise_next_week_count', title: '下周诺访', width: 80},
        ]
      }
    },
    components: {
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      loadings:Loadings,
    },
    created () {
      this.getReport()
    },
    methods: {
      getReport(){
        this.loading = true
        this.$api.custom_huidong_report_studio_week().then(res => {
          this.tableData = res.data
          this.list = res.data
          this.loading = false
        })
      },
      userInfo(rowData){
        return (
          <div class="crm-user">
              <img src={rowData.studio_cover}/>
              <span>{rowData.studio_name}</span>
          </div>
        )
      },
      sortChange(params){
          let str = ''
          let sortStr = ''
          for(let key in params){
            if(params[key]){
              str = key
              sortStr = params[key]
            }
          }
          if(sortStr && sortStr == 'asc'){
            this.toSort(str,1)
          }else if(sortStr && sortStr == 'desc'){
            this.toSort(str,2)
          }else{
            this.toSort(str,3)
          }
      },
      toSort(str,num){
        if(num !=3){
          var compare = function(obj1,obj2){
            var val1 = obj1[str]
            var val2 = obj2[str]
            if(val1<val2){
              return num == 1? 1 : -1
            }else if(val1>val2){
              return num == 1? -1 : 1
            }else{
              return 0
            }
          }
          this.tableData = this.tableData.sort(compare)
        }else{
          this.tableData = this.list
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  .crm-item{
    background: #fff;
    padding: 0 10px;
    .van-col{
      // text-align: center;
      min-height: 40px;
      // line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .chart-title{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }
    .crm-item-name{
      overflow: hidden;
      text-overflow:ellipsis;/* 超出部分显示省略号 */
      white-space: nowrap;/*规定段落中的文本不进行换行 */
    }
    .crm-item-time{
      text-align: center;
    }
  }
</style>
<style lang="less">
  .crm-today{
    .crm-today-group{
      margin-bottom: 10px;
    }
    .van-cell-group__title{
      text-align: center;
      background: #fff;
    }
  }
  .crm-user{
    display: flex;
    align-items: center;
    img{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 5px;
        margin-left: 10px;
    }
    span{
      display: inline-block;
      width: 130px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      text-align:left;
    }
  }
  .v-table-empty-data{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 100%;
    color: #666;
    font-size: 14px;
    border: 1px solid #eee;
    border-top: 0;
  }
</style>